import { useEffect, useState } from 'react';
import { notificationTypes, primaryInterestChoices, roleChoices, userStatusChoices } from 'choices';
import Chat from 'components/chats/Chat';
import AvatarField from 'fields/AvatarField';
import MuiPagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  ImageInput,
  Datagrid,
  TextField,
  EmailField,
  ArrayField,
  DateField,
  SingleFieldList,
  ChipField,
  ImageField,
  BooleanField,
  SelectInput,
  PasswordInput,
  ReferenceField,
  NumberField,
  useRecordContext,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

import axios from 'utils/axios';
import {
  Grid,
  Typography,
  Paper,
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import TextArrayField from 'fields/TextArrayField';
import { defaultPerPage, FRONTEND_URL, getAdminUser, getTokenFromResponse } from 'utils/helpers';
import Pagination from 'components/Pagination';
import fileDownload from 'js-file-download';

const ExportEmails = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (path, ext = 'txt') =>
    axios
      .get(`/v1/users/${path}`, {
        responseType: 'text',
      })
      .then(response => {
        fileDownload(response.data, `${path}-${new Date()}.${ext}`);
      })
      .catch(() => {});

  return (
    <div style={{ position: 'relative', top: -10 }}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        color="primary"
        variant="contained"
      >
        Download Emails
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleDownload('influencer_contact_emails?adult=true')}>
          Adult Influencers
        </MenuItem>
        <MenuItem onClick={() => handleDownload('influencer_contact_emails?adult=false')}>
          Non-Adult Influencers
        </MenuItem>
        <MenuItem onClick={() => handleDownload('influencer_contact_emails_csv?adult=true', 'csv')}>
          Adult Influencer CSV
        </MenuItem>
        <MenuItem
          onClick={() => handleDownload('influencer_contact_emails_csv?adult=false', 'csv')}
        >
          Non-Adult Influencer CSV
        </MenuItem>
        <MenuItem onClick={() => handleDownload('advertiser_contact_emails')}>Advertisers</MenuItem>
      </Menu>
    </div>
  );
};

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <BooleanInput source="topLevel.completed" label="Completed" alwaysOn />,
  <SelectInput source="role" choices={roleChoices} />,
  <ExportEmails alwaysOn />,
];

const MasqueradeUserField = ({ record }) => {
  const masquerade = e => {
    e.preventDefault();
    e.stopPropagation();

    axios.post(`/v1/users/${record.id}/masquerade`).then(response => {
      const token = getTokenFromResponse(response);
      const frame = document.getElementById('masquerade-frame');

      if (token && frame) {
        frame.contentWindow.postMessage({ token }, FRONTEND_URL);
        window.open(FRONTEND_URL, '_blank');
      }
    });
  };

  return (
    <Button variant="contained" color="primary" size="small" onClick={masquerade}>
      Login
    </Button>
  );
};

const ProfileUrlField = ({ record }) => {
  const prefix = record.role === 'influencer' ? '/u' : '/c';
  const username =
    record.role === 'influencer'
      ? (record.profiles.find(p => p.id === record.profile_id) || record.profiles[0])?.username
      : record.username;

  if (!username) return null;

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      href={`${FRONTEND_URL}${prefix}/${username}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      Profile
    </Button>
  );
};

const ActionsField = props => (
  <div style={{ display: 'flex', gap: 5 }}>
    <ProfileUrlField {...props} />
    <MasqueradeUserField {...props} />
  </div>
);

const LoginField = ({ record }) => record?.phone || record?.email;

const CustomFieldList = props => {
  const fixedProps = {
    ...props,
    ids: props.ids.slice(0, 3),
    data: Object.fromEntries(Object.entries(props.data).slice(0, 3)),
  };

  return (
    <SingleFieldList linkType="show" {...fixedProps}>
      <ChipField source="username" />
    </SingleFieldList>
  );
};

export const UserList = props => (
  <>
    <List
      {...props}
      perPage={defaultPerPage}
      pagination={<Pagination />}
      filters={listFilters}
      filterDefaultValues={{ topLevel: { completed: true } }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick="edit">
        <AvatarField source="avatar_url" label="Avatar" sortable={false} />
        <TextField source="id" />
        <TextField source="name" sortBy="first_name" />
        <LoginField label="Login" />
        <ChipField source="role" />
        <BooleanField source="full_management_agency" label="Mgmt Agency" />

        <DateField source="created_at" />
        <BooleanField source="confirmed" sortBy="confirmed_at" />

        <ArrayField source="profiles" basePath="profiles" fieldKey="id" sortable={false}>
          <CustomFieldList />
        </ArrayField>

        <ActionsField label="Actions" />
      </Datagrid>
    </List>

    <iframe
      id="masquerade-frame"
      title="MasqueradeFrame"
      src={`${FRONTEND_URL}/masquerade-frame`}
      width={0}
      height={0}
    />
  </>
);

const ProfileList = ({ user }) => (
  <Paper style={{ marginTop: 20 }}>
    <div style={{ padding: 20 }}>
      <Typography variant="h6">Profiles</Typography>

      <small>Grayed out is the user's currently selected profile</small>
    </div>

    <List
      basePath="/profiles"
      resource="profiles"
      perPage={25}
      filter={{ user_id: user.id }}
      actions={false}
      bulkActionButtons={false}
    >
      <Datagrid
        rowClick="edit"
        rowStyle={profile => (user.profile_id === profile.id ? { backgroundColor: '#eee' } : {})}
      >
        <TextField source="id" label="ID" />
        <AvatarField source="avatar_url" label="Avatar" sortable={false} />
        <TextField source="display_name" />
        <TextField source="username" />
        <TextField source="gender" />
        <TextField source="audience_gender" />
        <BooleanField source="completed" />
        <TextArrayField source="categories" sortable={false} />
        <TextField source="sub_category.name" label="Sub Category" sortable={false} />
        <TextArrayField source="audiences" sortable={false} />
        <TextArrayField source="experiences" sortable={false} />
      </Datagrid>
    </List>
  </Paper>
);

export const GigList = ({ userId }) => (
  <Paper style={{ marginTop: 20 }}>
    <div style={{ padding: 20 }}>
      <Typography variant="h6">Gigs</Typography>
    </div>

    <List
      basePath="/gigs"
      resource="gigs"
      perPage={25}
      filter={{ user_id: userId }}
      actions={false}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="status" />
        <BooleanField source="public" />

        <ReferenceField
          source="profile_id"
          reference="profiles"
          label="Influencer"
          sortable={false}
        >
          <TextField source="display_name" />
        </ReferenceField>

        <NumberField source="budget" />
        <NumberField source="advertiser_fee_amount" />
        <NumberField source="total_amount" />
        <NumberField source="amount" />
        <NumberField source="influencer_fee_amount" />

        <DateField source="created_at" />
      </Datagrid>
    </List>
  </Paper>
);

const AmountField = ({ source }) => {
  const record = useRecordContext();
  const value = record[source];
  const sign = ['credit', 'refund'].includes(record.kind) ? '+' : '-';
  const color = sign === '-' ? 'red' : 'green';

  return <span style={{ color }}>{`${sign}$${value}`}</span>;
};

const WalletInfo = ({ id, ...rest }) => {
  const user = getAdminUser();
  const [wallet, setWallet] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [transactionPage, setTransactionPage] = useState(1);
  const [transactionTotalCount, setTransactionTotalCount] = useState(0);
  const [payouts, setPayouts] = useState([]);
  const [payoutPage, setPayoutPage] = useState(1);
  const [payoutTotalCount, setPayoutTotalCount] = useState(0);

  useEffect(() => {
    axios.get(`/v1/wallets/${id}`).then(response => {
      setWallet(response.data);
    });

    axios
      .get(`/v1/wallets/${id}/transactions?page=${transactionPage}&per_page=25`)
      .then(response => {
        setTransactions(response.data);
        setTransactionTotalCount(response.headers['pagination-total-count']);
      });

    axios
      .get(`/v1/wallets/${id}/transactions?kind=payout&page=${payoutPage}&per_page=25`)
      .then(response => {
        setPayouts(response.data);
        setPayoutTotalCount(response.headers['pagination-total-count']);
      });
  }, [id, transactionPage, payoutPage]);

  const handleDownload = () =>
    axios
      .get(`/v1/wallets/${id}/export_transactions_csv`, {
        responseType: 'blob',
      })
      .then(response => {
        fileDownload(response.data, `gig-social-transactions-${new Date()}.csv`);
      })
      .catch(() => {});

  const resetTaxStatus = () => {
    axios.put(`/v1/wallets/${id}`, { wallet: { tax_form_status: 'pending' } }).then(response => {
      setWallet(response.data);
    });
  };

  if (!wallet) return null;

  return (
    <Paper style={{ marginTop: 20 }}>
      <div style={{ padding: 20 }}>
        <Typography variant="h6">Wallet: ${wallet.available_balance}</Typography>
        <Typography variant="h6">MassPay Email: {wallet.email}</Typography>
        <Typography variant="h6">
          {wallet.mass_pay_user_token ? (
            <a
              style={{ fontSize: 15 }}
              href={`https://clients.masspay.io/customer/${wallet.mass_pay_user_token}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              MassPay
            </a>
          ) : (
            <>MassPay</>
          )}
          {': '}${wallet.true_balance}
        </Typography>
        <Typography variant="h6">ID: {wallet.id}</Typography>
        <IconButton onClick={handleDownload}>
          <svg
            onClick={handleDownload}
            width="30px"
            height="30px"
            viewBox="0 0 46 60"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>icons/blurb/post</title>
            <g
              id="//-Symbols"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <g
                id="uploadModule-Doc"
                transform="translate(-42.000000, -35.000000)"
                stroke="#5B00DB"
                stroke-width="3"
              >
                <g id="Group" transform="translate(35.000000, 35.000000)">
                  <g transform="translate(9.000000, 2.000000)">
                    <path
                      d="M0,5 L0,51 C3.38176876e-16,53.7614237 2.23857625,56 5,56 L37,56 C39.7614237,56 42,53.7614237 42,51 L42,14.9333333 L42,14.9333333 L26,0 L5,0 C2.23857625,5.07265313e-16 -3.38176876e-16,2.23857625 0,5 Z"
                      id="Stroke-1"
                    ></path>
                    <path
                      d="M26,0 L26,14.8910891 L42,14.8910891 M10,18.6138614 L18,18.6138614 M10,27.9207921 L32,27.9207921 M10,37.2277228 L32,37.2277228 M10,46.5346535 L28,46.5346535"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </IconButton>
      </div>

      <div
        style={{
          padding: 20,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6">
          <strong>Identity Document</strong>
          <br />
          <span>Status: {wallet.identity_document_status}</span>
          <br />
          {wallet.identity_document_url && (
            <span>
              URL:{' '}
              <a href={wallet.identity_document_url} target="_blank" rel="noopener noreferrer">
                View ID
              </a>
            </span>
          )}
          <br />
          {wallet.identity_document_data && (
            <span>
              Extracted data:{' '}
              <JsonField
                reactJsonOptions={{
                  collapsed: true,
                }}
                record={wallet}
                source="identity_document_data"
              />
            </span>
          )}
        </Typography>

        <Typography variant="h6">
          <strong>Tax Form</strong>
          <br />
          <span>Status: {wallet.tax_form_status}</span>
          {wallet.tax_form_status === 'completed' && user.super_admin && user.super_admin === true && (
            <Button size="small" color="primary" onClick={resetTaxStatus}>
              Reset
            </Button>
          )}
          <br />
        </Typography>
      </div>

      {transactions.length > 0 && (
        <>
          <div style={{ padding: 20 }}>
            <Typography variant="h6">Transactions</Typography>
          </div>

          <ArrayField {...rest} record={{ transactions }} source="transactions">
            <Datagrid rowClick={null}>
              <TextField source="id" label="ID" />
              <TextField source="kind" />
              <AmountField source="amount" />
              <TextField source="description" />
              <DateField source="created_at" />
            </Datagrid>
          </ArrayField>

          <MuiPagination
            count={Math.ceil(transactionTotalCount / 25)}
            page={transactionPage}
            style={{ display: 'flex', justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}
            onChange={(e, value) => setTransactionPage(value)}
          />
        </>
      )}

      {payouts.length > 0 && (
        <>
          <div style={{ padding: 20 }}>
            <Typography variant="h6">Payouts</Typography>
          </div>

          <ArrayField
            {...rest}
            record={{
              payouts,
            }}
            source="payouts"
          >
            <Datagrid rowClick={null}>
              <TextField source="id" label="ID" />
              <TextField source="status" />
              <AmountField source="amount" />

              <DateField source="created_at" />
            </Datagrid>
          </ArrayField>

          <MuiPagination
            style={{ display: 'flex', justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}
            count={Math.ceil(payoutTotalCount / 25)}
            page={payoutPage}
            onChange={(e, value) => setPayoutPage(value)}
          />
        </>
      )}
    </Paper>
  );
};

const ConfirmUserButton = ({ record }) => {
  const confirm = e => {
    e.preventDefault();
    e.stopPropagation();

    axios
      .put(`/v1/users/${record.id}`, { user: { confirmed_at: new Date() } })
      .then(() => window.location.reload());
  };

  return (
    <Button variant="contained" color="primary" size="small" onClick={confirm}>
      Confirm
    </Button>
  );
};

const ResendConfirmationButton = ({ record }) => {
  const [disabled, setDisabled] = useState(false);

  const resend = e => {
    e.preventDefault();
    e.stopPropagation();

    axios
      .post(`/confirmations`, { user: { login: record?.phone || record?.email } })
      .then(() => setDisabled(true));
  };

  return (
    <Button variant="contained" color="primary" size="small" onClick={resend} disabled={disabled}>
      Resend Confirmation
    </Button>
  );
};

const ActionsCard = ({ record }) => (
  <Typography
    variant="h6"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'space-between',
      marginBottom: 20,
    }}
  >
    Actions: <br />
    <div style={{ maxWidth: 200 }}>
      <MasqueradeUserField record={record} />
      {!record.confirmed && (
        <>
          <br /> <br />
          <ResendConfirmationButton record={record} />
          <br /> <br />
          <ConfirmUserButton record={record} />
        </>
      )}
    </div>
  </Typography>
);

const EditLayout = props => {
  const [college, setCollege] = useState(false);

  return (
    <>
      <Paper>
        <SimpleForm {...props} mutationMode="pessimistic">
          <ActionsCard />

          <hr style={{ width: '100%' }} />

          <Typography variant="h6" style={{ marginLeft: 10 }}>
            User
          </Typography>

          <Grid container spacing={2} style={{ width: '100%' }}>
            <Grid item md={6}>
              <TextInput fullWidth source="first_name" />
              <TextInput fullWidth source="last_name" />
              <TextInput fullWidth source="display_name" />
              <TextInput fullWidth source="email" />
              <TextInput fullWidth source="contact_email" />
              <TextInput fullWidth source="contact_phone" />
              <TextInput fullWidth source="contact_phone_ext" />
              <TextInput fullWidth source="company_title" />
              <SelectInput fullWidth source="status" choices={userStatusChoices} />
            </Grid>

            <Grid item md={6}>
              <SelectInput fullWidth source="role" choices={roleChoices}></SelectInput>

              <FormControlLabel
                control={
                  <Switch
                    checked={college}
                    onChange={e => setCollege(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="College (only for school search)"
              />

              <ReferenceInput
                source="school_id"
                reference="schools"
                label="School"
                allowEmpty
                filter={{ topLevel: { kind: college ? 'college' : 'school' } }}
                filterToQuery={searchText => ({
                  topLevel: { q: searchText, kind: 'college' },
                })}
              >
                <AutocompleteInput
                  autocomplete="off"
                  id="search_school"
                  fullWidth
                  optionText={record => (record?.id ? `${record?.name}` : '')}
                />
              </ReferenceInput>

              <BooleanInput fullWidth source="partner" />
              <BooleanInput fullWidth source="collective" />
              <BooleanInput fullWidth source="adult" />
              <BooleanInput fullWidth source="full_management_agency" />
              <SelectInput fullWidth source="primary_interest" choices={primaryInterestChoices} />

              <TextInput fullWidth source="phone" />
              <SelectInput
                fullWidth
                source="notification_type"
                choices={notificationTypes}
              ></SelectInput>

              <TextInput fullWidth source="success_score" disabled />
              <TextInput fullWidth source="average_rating" disabled />

              <ImageField source="avatar_url" label="Current Avatar" />

              <ImageInput source="avatar" accept="image/*, svg+xml">
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Paper>

      {props?.record?.role === 'influencer' && <ProfileList user={props?.record} />}

      {props?.record?.role === 'advertiser' && <GigList userId={props?.record?.id} />}

      {props?.record?.wallet_id && <WalletInfo id={props?.record?.wallet_id} />}

      {/* {props?.record?.role !== 'influencer' && user.id !== props?.record?.id && (
        <Paper style={{ marginTop: 20 }}>
          <Typography variant="h6" style={{ padding: 20 }}>
            Chat
          </Typography>

          <Chat recipientType="User" recipientId={props?.record?.id} sidebar={false} />
        </Paper>
      )} */}
      <iframe
        id="masquerade-frame"
        title="MasqueradeFrame"
        src={`${FRONTEND_URL}/masquerade-frame`}
        width={0}
        height={0}
      />
    </>
  );
};

export const UserEdit = props => (
  <Edit component="div" {...props} mutationMode="pessimistic">
    <EditLayout {...props} />
  </Edit>
);

export const UserCreate = props => {
  const [college, setCollege] = useState(false);

  return (
    <Create {...props} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput source="first_name" />
        <TextInput source="last_name" />
        <TextInput source="display_name" />
        <TextInput source="email" />
        <TextInput source="contact_email" />
        <TextInput source="phone" />
        <SelectInput source="notification_type" choices={notificationTypes}></SelectInput>
        <TextInput source="contact_phone" />
        <TextInput source="contact_phone_ext" />
        <TextInput source="company_title" />
        <PasswordInput source="password" />

        <SelectInput source="role" choices={roleChoices}></SelectInput>

        <FormControlLabel
          control={
            <Switch
              checked={college}
              onChange={e => setCollege(e.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="College (only for school search)"
        />

        <ReferenceInput
          source="school_id"
          reference="schools"
          label="School"
          allowEmpty
          filterToQuery={searchText => ({
            topLevel: { q: searchText, kind: college ? 'college' : 'school' },
          })}
        >
          <AutocompleteInput
            autocomplete="off"
            id="search_school"
            fullWidth
            optionText={record => (record?.id ? `${record?.name}` : '')}
          />
        </ReferenceInput>

        <BooleanInput fullWidth source="collective" />

        <ImageField source="avatar_url" label="Current Avatar" />

        <ImageInput source="avatar" accept="image/*, svg+xml">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <AvatarField source="avatar_url" label="Avatar" />
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <EmailField source="contact_email" />
      <TextField source="phone" />
      <TextField source="contact_phone" />
      <TextField source="contact_phone_ext" />
      <TextField source="company_title" />
      <TextField source="role" />
      <DateField source="created_at" />
      <BooleanField source="confirmed" />
      <ArrayField source="profiles" resource="profiles">
        <Datagrid resource="profiles" rowClick="show">
          <TextField source="id" />
          <ImageField source="avatar_url" label="Avatar" />
          <TextField source="username" />
          <TextField source="display_name" label="Display Name" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
